var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"featured-projects"},[_vm._m(0),(!_vm.loading)?_c('div',[_c('div',{staticClass:"featured-projects pb-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"row"},[_vm._l((_vm.projects),function(project,i){return _c('div',{key:`featured-${i}`,staticClass:"col-md-4"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{
                          name: 'dashboard.projects.show',
                          params: { id: project.id },
                        }}},[_c('span',{staticClass:"category"},[_vm._v(_vm._s(project.sector))]),_c('img',{staticClass:"card-img-top",staticStyle:{"height":"150px","width":"auto"},attrs:{"src":project.logo,"alt":"featured"}})])],1),_c('div',{staticClass:"text-muted text-sm"},[_c('i',{staticClass:"fa fa-map-marker me-2"}),_vm._v(_vm._s(project.region)+" ")]),_c('h6',{staticClass:"my-2"},[_c('router-link',{attrs:{"to":{
                          name: 'dashboard.projects.show',
                          params: { id: project.id },
                        }}},[_vm._v(_vm._s(project.name))])],1),_c('p',[_vm._v(" "+_vm._s(project.description)+"... ")]),_c('router-link',{staticClass:"text-primary",attrs:{"to":{
                        name: 'dashboard.projects.show',
                        params: { id: project.id },
                      }}},[_vm._v("Read More")])],1)])])}),(_vm.projects.length == 0)?_c('div',{staticClass:"col-md-12"},[_c('alert',{attrs:{"type":"info"}},[_vm._v("There is no data to display.")])],1):_vm._e()],2)]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body bg-white"},[_vm._m(1),_c('hr'),_c('h6',{staticClass:"mb-3"},[_vm._v("Sectors")]),_c('ul',{staticClass:"sidebar-menu"},_vm._l((_vm.sectors),function(sector,i){return _c('li',{key:`sector-${i}`},[_c('router-link',{attrs:{"to":""}},[_c('i',{staticClass:"fa fa-caret-right me-2"}),_vm._v(_vm._s(sector.name))])],1)}),0),_c('hr'),_c('h6',{staticClass:"mb-3"},[_vm._v("Geographic Region")]),_c('ul',{staticClass:"sidebar-menu"},[_c('li',[_c('router-link',{attrs:{"to":""}},[_c('i',{staticClass:"fa fa-caret-right me-2"}),_vm._v("West Africa")])],1),_c('li',[_c('router-link',{attrs:{"to":""}},[_c('i',{staticClass:"fa fa-caret-right me-2"}),_vm._v("East And Central Africa "),_c('span',[_vm._v("5")])])],1),_c('li',[_c('router-link',{attrs:{"to":""}},[_c('i',{staticClass:"fa fa-caret-right me-2"}),_vm._v("Southern Africa")])],1)]),_c('hr'),_c('h6',[_vm._v("Tags")]),_c('p',_vm._l((10),function(i){return _c('span',{key:`tag-${i}`,staticClass:"badge bg-secondary me-2"},[_vm._v("Tag "+_vm._s(i))])}),0)])])])])])])]):_vm._e(),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h4',{staticClass:"underlined"},[_vm._v("Our Projects")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"search-projects"},[_c('div',{staticClass:"input-group mb-3"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Search..."}}),_c('button',{staticClass:"btn btn-link"},[_c('i',{staticClass:"fa fa-search"})])])])
}]

export { render, staticRenderFns }